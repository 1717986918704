html {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-prev {
  left: 0px !important
}

.swiper-button-next {
  right: 0px !important
}

.swiper-button-prev, .swiper-button-next {
  color: #165c50 !important;
  height: 20px !important;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 24px !important;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}